import Footer from "../../../common/Footer";
import HeroSection from "../../Platform/HeroSection";
import HRMSFeaturesTabs from "../components/HRMSFeaturesTabs";

const HrGenie = () => {
  return (
    <>
      <HeroSection />
      <HRMSFeaturesTabs />
      <Footer />
    </>
  );
};

export default HrGenie;
