/**
 * Validates an email address using a regular expression pattern.
 * @param {string} email - The email address to validate.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
export function validateEmail(email: string): boolean {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
}
/**
 * Validates that the input value contains only text (letters and spaces).
 * @param {any} rule - The validation rule object.
 * @param {any} value - The value to be validated.
 * @param {any} callback - The callback function to handle validation results.
 * @returns None
 */
export const validateTextOnly = (rule: any, value: any, callback: any) => {
  const regex = /^[A-Za-z\s]+$/;

  if (!regex.test(value)) {
    callback("Please enter text only.");
  } else {
    callback();
  }
};
/**
 * Checks if the input string contains only numbers.
 * @param {string} input - The input string to be checked.
 * @returns {boolean} True if the input contains only numbers, false otherwise.
 */
export function restrictToNumbers(input: string): boolean {
  const numberRegex = /^[0-9]+$/;
  return numberRegex.test(input);
}
/**
 * Validates if the provided link is a valid Instagram profile link.
 * @param {string} link - The link to be validated.
 * @returns {boolean} - True if the link is a valid Instagram profile link, false otherwise.
 */
export function validateInstagramLink(link: string): boolean {
  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_]+\/?$/;
  return instagramRegex.test(link);
}
/**
 * Validates a Facebook link to ensure it matches the expected format.
 * @param {string} link - The Facebook link to validate.
 * @returns {boolean} - True if the link is valid, false otherwise.
 */
export function validateFacebookLink(link: string): boolean {
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9_\-]+\/?$/;
  return facebookRegex.test(link);
}
/**
 * Validates a Twitter link to ensure it matches the expected format.
 * @param {string} link - The Twitter link to validate.
 * @returns {boolean} - True if the link is a valid Twitter link, false otherwise.
 */
export function validateTwitterLink(link: string): boolean {
  const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/[A-Za-z0-9_]+\/?$/;
  return twitterRegex.test(link);
}

/**
 * Validates a LinkedIn profile link to ensure it matches the expected format.
 * @param {string} link - The LinkedIn profile link to validate.
 * @returns {boolean} True if the link is a valid LinkedIn profile link, false otherwise.
 */
export function validateLinkedInLink(link: string): boolean {
  const linkedinRegex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_\-]+\/?$/;
  return linkedinRegex.test(link);
}
/**
 * Validates a social media link to check if it is a valid URL for LinkedIn, Facebook, Instagram, or Twitter.
 * @param {any} link - The social media link to validate.
 * @returns {boolean} True if the link is a valid social media URL, false otherwise.
 */
export const validateSocialMediaLink = (link: any) => {
  const trimmedLink = link?.trim();
  if (
    trimmedLink.startsWith("https://") ||
    trimmedLink?.startsWith("http://")
  ) {
    const withoutProtocol = trimmedLink?.replace(/^(https?:\/\/)/i, "");

    if (
      withoutProtocol?.match(/^(www\.)?linkedin\.com\//i) ||
      withoutProtocol?.match(/^(www\.)?facebook\.com\//i) ||
      withoutProtocol?.match(/^(www\.)?instagram\.com\//i) ||
      withoutProtocol?.match(/^(www\.)?twitter\.com\//i)
    ) {
      return true;
    }
  }

  return false;
};
/**
 * Capitalizes the first letter of each word in a given string.
 * @param {string} str - The input string to capitalize.
 * @returns {string} A new string with the first letter of each word capitalized.
 */
export const capitalizeWords = (str: string) => {
  return str?.toLowerCase()?.replace(/(^|\s)\S/g, function (firstLetter) {
    return firstLetter?.toUpperCase();
  });
};

/**
 * Validates a website URL using a regular expression pattern.
 * @param {string} website - The website URL to validate.
 * @returns {boolean} Returns true if the website URL is valid, false otherwise.
 */
export const validateWebsite = (website: string) => {
  const websiteRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  return website === "" || websiteRegex.test(website);
};

/**
 * Validates a password based on the following criteria:
 * - At least one digit
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one special character (!@#$%^&*)
 * - Minimum length of 8 characters
 * @param {string} password - The password to validate
 * @returns {boolean} True if the password meets the criteria, false otherwise
 */
export const validatePassword = (password: string) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
  return password === "" || passwordRegex.test(password);
};

/**
 * Validates a Facebook link based on a regular expression pattern.
 * @param {string} link - The Facebook link to validate.
 * @returns {boolean} True if the link is valid, false otherwise.
 */
export const validateFacebook = (link: string) => {
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/([a-zA-Z0-9.]+)/i;
  return link === "" || facebookRegex.test(link);
};

/**
 * Validates a LinkedIn profile link based on a regular expression pattern.
 * @param {string} link - The LinkedIn profile link to validate.
 * @returns {boolean} Returns true if the link is valid according to the regex pattern, false otherwise.
 */
export const validateLinkedin = (link: string) => {
  const linkedinRegex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/([a-zA-Z0-9.-]+)/i;
  return link === "" || linkedinRegex.test(link);
};
/**
 * Generates a random password of length 8 using a predefined character set.
 * @returns {string} A randomly generated password.
 */
export const generateRandomPassword = () => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=[]{}|;:,.<>?";
  let password = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

/**
 * Checks if the domain of the company email matches the domain of the entered email.
 * @param {string} companyemail - The company email address.
 * @param {string} enteredemail - The entered email address to compare with the company email.
 * @returns {boolean} Returns true if the domains match, false otherwise.
 */
export const domaincheck = (companyemail: string, enteredemail: string) => {
  const domain1 = companyemail?.split("@")[1];
  const domain2 = enteredemail?.split("@")[1];

  return domain1 === domain2;
};
/**
 * Removes all spaces from a given word.
 * @param {any} word - The word from which spaces need to be removed.
 * @returns The word without any spaces.
 */
export const removeSpaces = (word: any) => {
  return word?.replace(/\s+/g, "");
};
/**
 * Extracts the capitalized initials from a given input string.
 * @param {string} inputString - The input string from which to extract the initials.
 * @returns A string containing the capitalized initials extracted from the input string.
 */
export const extractCapitalizedInitials = (inputString: string) => {
  return inputString
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase())
    ?.join("");
};
/**
 * Generates a random 4-digit number.
 * @returns {number} A random 4-digit number between 1000 and 9999 (inclusive).
 */
export const generateRandom4DigitNumber = () => {
  const min = 1000;
  const max = 9999;
  return Math?.floor(Math?.random() * (max - min + 1)) + min;
};

/**
 * Shortens a given string by taking the first two characters of each word after splitting by spaces.
 * @param {string} str - The input string to be shortened.
 * @returns {string} The shortened string.
 */
export const shortenString = (str: string) => {
  return str
    ?.split(/\s+/)
    ?.map((word) => word?.substring(0, 2))
    ?.join("");
};
/**
 * Generates a formatted ID based on the input string and number.
 * @param {string} input - The input string used to generate the ID.
 * @param {string} number - The number used in the ID generation.
 * @returns {string} The formatted ID combining the first letters of words in the input string
 * and the last three digits of the number.
 */
export const generateFormatedId = (input: string, number: string) => {
  const words = input?.split(" ");
  let result = "";

  if (words.length === 1) {
    result = words[0]?.substring(0, 3)?.toUpperCase();
  } else {
    for (const word of words) {
      result += word?.charAt(0)?.toUpperCase();
    }
  }

  const numberString = String(number);
  const lastThreeDigits = numberString?.substring(numberString.length - 4);

  result = `${result}-${lastThreeDigits}`;

  return result;
};
// Custom date formatting function
export const CustomformatDate = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`
};
