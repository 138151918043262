import React from "react";
import BgContentContainer from "../../../../Platform/BgContentContainer";
import { PolicyDocumentConstants } from "../../../../../constants/PlatformAttributeConstants";
import PlainContentContainer from "../../../../Platform/PlainContantContainer";
import { storeFrontQuestion } from "../../../../../common/Genie/questions";

const PlatformPolicy = () => {
  return (
    <>
      <div>
        <div>
          <BgContentContainer
            imgSrc={
              PolicyDocumentConstants.PolicyDocument_Management_Deductoin_Policy
                .img
            }
            heading={
              PolicyDocumentConstants.PolicyDocument_Management_Deductoin_Policy
                .heading
            }
            description={
              PolicyDocumentConstants.PolicyDocument_Management_Deductoin_Policy
                .description
            }
            questionId={2}
            arrayName={storeFrontQuestion.policy}
          />
          <PlainContentContainer
            imgSrc={
              PolicyDocumentConstants.PolicyDocument_Management_Timing_Policy
                .img
            }
            heading={
              PolicyDocumentConstants.PolicyDocument_Management_Timing_Policy
                .heading
            }
            description={
              PolicyDocumentConstants.PolicyDocument_Management_Timing_Policy
                .description
            }
            questionId={3}
            arrayName={storeFrontQuestion.policy}
          />
          <BgContentContainer
            imgSrc={
              PolicyDocumentConstants.PolicyDocument_Management_Overtime.img
            }
            heading={
              PolicyDocumentConstants.PolicyDocument_Management_Overtime.heading
            }
            description={
              PolicyDocumentConstants.PolicyDocument_Management_Overtime
                .description
            }
            questionId={4}
            arrayName={storeFrontQuestion.policy}
          />
          <PlainContentContainer
            imgSrc={
              PolicyDocumentConstants.PolicyDocument_Management_Absent_Policy
                .img
            }
            heading={
              PolicyDocumentConstants.PolicyDocument_Management_Absent_Policy
                .heading
            }
            description={
              PolicyDocumentConstants.PolicyDocument_Management_Absent_Policy
                .description
            }
            questionId={5}
            arrayName={storeFrontQuestion.policy}
          />
          <BgContentContainer
            imgSrc={
              PolicyDocumentConstants.PolicyDocument_Management_Overtime.img
            }
            heading={
              PolicyDocumentConstants.PolicyDocument_Management_Overtime.heading
            }
            description={
              PolicyDocumentConstants.PolicyDocument_Management_Overtime
                .description
            }
            questionId={6}
            arrayName={storeFrontQuestion.policy}
          />
        </div>
      </div>
    </>
  );
};
export default PlatformPolicy;
