import "./index.css";
import genie from "../../../assets/svgs/pricing-svgs/genie.svg";


const HeroSection: React.FC = () => {
  return (
    <div className="pricing-hero-container">
      <img src={genie} alt="Genie Icon" className="pricing-hero-icon" />
      <span className="pricing-hero-heading storefront-hero-heading">Unlock the Magic of Efficiency with MisGenie</span>
    </div>
  );
};

export default HeroSection;
