import React from 'react';
import "./index.css"
const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-container">
      <p><strong>Last Updated:</strong> 1 September 2024</p>

      <p>
        Welcome to MisGenie. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
      </p>

      <h2>1. Information We Collect</h2>
      <p>MisGenie collects the following types of personal information from users and organizations:</p>
      <ul>
        <li><strong>Personal Identification Information</strong>: Name, email address, phone number, company information, and job title.</li>
        <li><strong>HR Data</strong>: Employee data such as payroll information, attendance, performance reviews, and recruitment data.</li>
        <li><strong>Financial Information</strong>: Payment information for services, invoices, and any necessary billing details.</li>
        <li><strong>Usage Data</strong>: Information about how users interact with our platform, including IP addresses, browser type, and usage statistics.</li>
        <li><strong>Cookies</strong>: MisGenie uses cookies and similar tracking technologies to enhance user experience and analyze website traffic.</li>
      </ul>

      <h2>2. How We Collect Your Data</h2>
      <p>We collect data in the following ways:</p>
      <ul>
        <li><strong>Direct Interactions</strong>: When you provide information by filling out forms on our website or using our services (e.g., HRIS, payroll, recruitment).</li>
        <li><strong>Automated Technologies</strong>: MisGenie uses cookies, server logs, and analytics services to collect data as you interact with our site.</li>
        <li><strong>Third-Party Integrations</strong>: MisGenie may collect information through third-party integrations (e.g., payroll services, AI analytics tools).</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>Providing, maintaining, and improving our platform and services.</li>
        <li>Facilitating HR, payroll, and client management processes.</li>
        <li>Assisting with performance management, recruitment, and AI-powered data analysis.</li>
        <li>Processing payments and managing financial transactions.</li>
        <li>Personalizing user experience and delivering content based on individual preferences.</li>
        <li>Monitoring platform performance and detecting potential security issues.</li>
      </ul>

      <h2>4. Data Sharing and Disclosure</h2>
      <p>MisGenie does not sell, trade, or rent your personal information to others. However, we may share your data with trusted third parties for the following purposes:</p>
      <ul>
        <li><strong>Service Providers</strong>: We may share information with third-party vendors that provide services such as payment processing, cloud storage, or AI analytics.</li>
        <li><strong>Legal Requirements</strong>: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
      </ul>

      <h2>5. User Rights and Control</h2>
      <p>You have the following rights concerning your data:</p>
      <ul>
        <li><strong>Access</strong>: Request a copy of the data we hold about you.</li>
        <li><strong>Correction</strong>: Request corrections to your personal information.</li>
        <li><strong>Deletion</strong>: Request deletion of your data, subject to legal obligations.</li>
        <li><strong>Opt-Out</strong>: You may opt out of marketing communications at any time.</li>
      </ul>
      <p>To exercise your rights, please contact us at <a href="mailto:support@misgenie.com">support@misgenie.com</a>.</p>

      <h2>6. Cookies and Tracking Technologies</h2>
      <p>MisGenie uses cookies to:</p>
      <ul>
        <li>Improve your user experience by remembering your preferences.</li>
        <li>Collect usage statistics for analytical purposes.</li>
        <li>Enhance site security.</li>
      </ul>

      <h2>7. Data Security</h2>
      <p>We take the security of your data seriously. MisGenie implements industry-standard security measures, including encryption, firewalls, and secure access controls, to protect your personal information. However, please note that no method of data transmission over the internet is completely secure.</p>

      <h2>8. Third-Party Services</h2>
      <p>MisGenie integrates with various third-party services such as payroll systems, recruitment portals, and AI data analytics tools. We are not responsible for the privacy practices of these third parties. We recommend reviewing their privacy policies to understand how they handle your data.</p>

      <h2>9. International Data Transfers</h2>
      <p>If you are located outside of [Insert Country], your data may be transferred to and processed in [Insert Country] where our servers are located. By using our services, you consent to the transfer of your data across international borders.</p>

      <h2>10. Children's Privacy</h2>
      <p>MisGenie’s services are not directed at children under 18, and we do not knowingly collect personal information from children.</p>

      <h2>11. Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Please review this policy periodically for any updates.</p>

      <h2>12. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal data, please contact us at:</p>
      <ul>
        <li><strong>Company Name</strong>: MisGenie</li>
        <li><strong>Email</strong>: <a href="mailto:support@misgenie.com">support@misgenie.com</a></li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
