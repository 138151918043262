import "./index.css";
import automationIcon from "../../../assets/svgs/home-svgs/automation.svg";
import payrollIcon from "../../../assets/svgs/home-svgs/payroll.svg";
import AIicon from "../../../assets/svgs/home-svgs/AI.svg";
import userIcon from "../../../assets/svgs/home-svgs/user.svg";
import { motion } from "framer-motion";
import { Col, Row } from "antd";

// const fadeInAnimationVariants = {
//   initial: {
//     opacity: 0,
//     y: "50vh",
//   },
//   animate: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       type: "spring",
//     }
//   }
// };

const headingVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const Fetures: React.FC = () => {
  const content = [
    {
      id: 1,
      title: "Automations",
      description:
        "Amplify your HR operations through multiple automation modules.",
      icon: automationIcon,
    },
    {
      id: 2,
      title: "Payroll",
      description:
        "Amplify your HR operations through multiple automation modules.",
      icon: payrollIcon,
    },
    {
      id: 3,
      title: "AI Integerations",
      description:
        "Amplify your HR operations through multiple automation modules.",
      icon: AIicon,
    },
    {
      id: 4,
      title: "Customized User Profile",
      description:
        "Amplify your HR operations through multiple automation modules.",
      icon: userIcon,
    },
  ];

  return (
    <motion.div className="features-container">
      <motion.h1
        className="features-title storefront-hero-heading"
        variants={headingVariants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true, amount: 0.5 }}
      >
        Solutions that Fuel your <span className="shadow-text">Efficiency</span>{" "}
      </motion.h1>
      <div className="cards-container">
        <Row gutter={[32, 32]}>
          {content.map((card, index) => (
            <Col span={24} md={12} lg={6}>
              <div
                key={card.id}
                className="home-feature-card"
                // variants={fadeInAnimationVariants}
                // initial="initial"
                // whileInView="animate"
                // viewport={{ once: true }}
              >
                <div className="card-icon-container">
                  <img
                    src={card.icon}
                    alt={card.title}
                    width="100"
                    height="auto"
                  />
                </div>
                <div className="card-text-content">
                  <span className="card-title">{card.title}</span>
                  <span className="home-card-description">
                    {card.description}
                  </span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </motion.div>
  );
};

export default Fetures;
