import React from "react";
import BgContentContainer from "../../../../Platform/BgContentContainer";
import {
  EmployeeManagementConstants
} from "../../../../../constants/PlatformAttributeConstants";
import PlainContentContainer from "../../../../Platform/PlainContantContainer";
import { storeFrontQuestion } from "../../../../../common/Genie/questions";

const PlatformEmployee = () => {
  return (
    <>
      <div>
        <div>
          <BgContentContainer
            imgSrc={EmployeeManagementConstants.Empl_Management_Profile.img}
            heading={
              EmployeeManagementConstants.Empl_Management_Profile.heading
            }
            description={
              EmployeeManagementConstants.Empl_Management_Profile.description
            }
            questionId={1}
            arrayName={storeFrontQuestion.employee}
          />
          <PlainContentContainer
            imgSrc={
              EmployeeManagementConstants.Empl_Management_Contact_Information
                .img
            }
            heading={
              EmployeeManagementConstants.Empl_Management_Contact_Information
                .heading
            }
            description={
              EmployeeManagementConstants.Empl_Management_Contact_Information
                .description
            }
            questionId={2}
            arrayName={storeFrontQuestion.employee}
          />
          <BgContentContainer
            imgSrc={
                EmployeeManagementConstants.Empl_Management_Work_Experience.img
              }
              heading={
                EmployeeManagementConstants.Empl_Management_Work_Experience
                  .heading
              }
              description={
                EmployeeManagementConstants.Empl_Management_Work_Experience
                  .description
              }
              questionId={3}
              arrayName={storeFrontQuestion.employee}
          />
          <PlainContentContainer
           imgSrc={EmployeeManagementConstants.Empl_Management_Skills.img}
           heading={EmployeeManagementConstants.Empl_Management_Skills.heading}
           description={
             EmployeeManagementConstants.Empl_Management_Skills.description
           }
           questionId={4}
           arrayName={storeFrontQuestion.employee}
          />
          <BgContentContainer
             imgSrc={
                EmployeeManagementConstants.Empl_Management_Certificaions.img
              }
              heading={
                EmployeeManagementConstants.Empl_Management_Certificaions.heading
              }
              description={
                EmployeeManagementConstants.Empl_Management_Certificaions
                  .description
              }
              questionId={5}
              arrayName={storeFrontQuestion.employee}
          />
          <PlainContentContainer
            imgSrc={
                EmployeeManagementConstants.Empl_Management_Qualifications.img
              }
              heading={
                EmployeeManagementConstants.Empl_Management_Qualifications.heading
              }
              description={
                EmployeeManagementConstants.Empl_Management_Qualifications
                  .description
              }
              questionId={6}
              arrayName={storeFrontQuestion.employee}
          />
          <BgContentContainer
             imgSrc={EmployeeManagementConstants.Empl_Management_Relative.img}
             heading={
               EmployeeManagementConstants.Empl_Management_Relative.heading
             }
             description={
               EmployeeManagementConstants.Empl_Management_Relative.description
             }
             questionId={7}
             arrayName={storeFrontQuestion.employee}
          />
          <PlainContentContainer
             imgSrc={EmployeeManagementConstants.Empl_Management_Banks.img}
             heading={EmployeeManagementConstants.Empl_Management_Banks.heading}
             description={
               EmployeeManagementConstants.Empl_Management_Banks.description
             }
             questionId={8}
             arrayName={storeFrontQuestion.employee}
          />
          <BgContentContainer
            imgSrc={EmployeeManagementConstants.Empl_Management_Salaries.img}
            heading={
              EmployeeManagementConstants.Empl_Management_Salaries.heading
            }
            description={
              EmployeeManagementConstants.Empl_Management_Salaries.description
            }
            questionId={9}
            arrayName={storeFrontQuestion.employee}
          />
        </div>
      </div>
    </>
  );
};
export default PlatformEmployee;
