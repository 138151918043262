import "./index.css";

const HeroSection: React.FC = () => {
  return (
    <div className="demo-hero-container">
      <div className="demo-hero-content-container">
        <span className="demo-hero-heading storefront-hero-heading">
          Experience the Magic of MisGenie
        </span>
        <span className="demo-description storefront-description">
          Step into a world where your business dreams come true with MisGenie.
          Unlock the secrets of seamless operations, enhanced productivity, and
          effortless management. Request your demo today and see the magic
          unfold.
        </span>
      </div>
    </div>
  );
};

export default HeroSection;
