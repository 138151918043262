import Footer from "../../../common/Footer";
import HeroSection from "../../Platform/HeroSection";
import PlatformPerformance from "../components/PerformanceGenie";

const PerformanceGenie = () => {
  return (
    <>
       <HeroSection />
       <PlatformPerformance />
       <Footer />
    </>
  )
}

export default PerformanceGenie;
