import { supabase_client_for_operation_portal_schema } from "../../services/supabase";

const customCatchError = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const storeContactUsFormData = async (body: any): Promise<any> => {
  try {
    const dataToInsert = { ...body, status: "Unanswered" };
    const res = await supabase_client_for_operation_portal_schema
      .from("ContactUs")
      .insert(dataToInsert);
    return res;
  } catch {
    return customCatchError;
  }
};
