import React, { useState } from "react";
import { Tabs } from "antd";
import "./index.css";
import { FaBuildingShield, FaClockRotateLeft } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { TabsProps } from "antd/lib";
import PlatformOrganization from "../HRMSFeatures/Organization";
import PlatformEmployee from "../HRMSFeatures/Employees";
import PlatformPolicy from "../HRMSFeatures/Policy";
import PlatformAttendance from "../HRMSFeatures/Attendance";


const HRMSFeaturesTabs: React.FC = () => {

    const items: TabsProps["items"] = [
        {
          label: "Organization",
          key: "1",
          children: <PlatformOrganization />,
          icon: <FaBuildingShield />
        },
        {
          key: "2",
          label: "Employee",
          children: <PlatformEmployee />,
          icon: <IoIosPeople />
        },
    
        {
          label: "Policy",
          key: "3",
          children: <PlatformPolicy />,
          icon: <></>
        },
        {
          label: "Attendance",
          key: "4",
          children: <PlatformAttendance />,
          icon: <FaClockRotateLeft />
        },
      ];


  return (
    <div className="home-features-carousal">
      <Tabs centered defaultActiveKey="3" type="card" animated={true} items={items} />
    </div>
  );
};

export default HRMSFeaturesTabs;
