import Footer from "../../../common/Footer";

const AiGenie = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "30rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1> AiGenie </h1>
      </div>
      <Footer />
    </>
  );
};

export default AiGenie;
