import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Select, Button, message } from "antd";
import { getCountries } from "vk-country-state-city";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "./index.css";
import { storeContactUsFormData } from "../services";
import TextArea from "antd/es/input/TextArea";
import { validateEmail } from "../../../common/Validation";

export const ContactUsForm: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [countries, setCountries] = useState<any[]>([]);
  const phoneUtil = PhoneNumberUtil.getInstance();

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
  }, []);

  const onFinish = async (values: any) => {
    const body = {
      name: values.full_name,
      email: values.bussinessEmail,
      country: values.country,
      phoneNumber: values.phoneNumber,
      message: values.message,
    };

    const data = await storeContactUsFormData(body);

    if (data.status == 201) {
      message.success("Form submitted successfully!");
      form.resetFields();
    } else {
      message.error("Sorry! There is some external error.");
    }
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <div className="contact-us-form-card-div">
          <Card className="contact-us-form-card">
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="full_name"
                    label="Full Name"
                    rules={[
                      { required: true, message: "Please Enter Full Name" },
                    ]}
                  >
                    <Input placeholder="Your Full Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="bussinessEmail"
                    label="Business Email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Email Address.",
                      },
                      {
                        validator: (_, value) => {
                          if (value && !validateEmail(value)) {
                            return Promise.reject(
                              new Error("Invalid email address!")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Your Business Email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="country" label="Select Country">
                    <Select
                      onChange={setSelectedCountry}
                      showSearch
                      allowClear
                      placeholder="Select Country"
                    >
                      {countries.map((item: any) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Phone Number",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      placeholder="Your Phone Number"
                      className="contact-us-phone-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Message" name="message">
                    <TextArea placeholder="Your Message..." rows={3} />
                  </Form.Item>
                </Col>
                <Col span={24} className="contact-us-form-button-div">
                  <Button htmlType="submit" className="contact-custom-button">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  );
};
