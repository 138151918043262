import Footer from "../../common/Footer";
import BgContentContainer from "./BgContentContainer";

import HeroSection from "./HeroSection";
import hrSvg from "../../assets/svgs/platformSvgs/hr-svg.svg";
import PlainContentContainer from "./PlainContantContainer";
import DemoCard from "../Home/DemoCard";
import PlatformCards from "./PlatformCards";
import AiGenie from "../../assets/images/storefront-images/platform/AIgenie.png";
import HRGenie from "../../assets/images/storefront-images/platform/HRGenie.png"
import { useNavigate } from "react-router-dom";


const Platform = () => {

  const navigate = useNavigate();

  return (
    <>
      <HeroSection />
      <BgContentContainer
        imgSrc={AiGenie}
        iconDisplay={true}
        svg={hrSvg}
        heading="AI Genie"
        description="Wave a wand over complex data; AI Genie casts spells to automate and predict, transforming your business insights magically."
        handleButtonClick={() => navigate("/platform/ai-genie")}
        buttonDisplay={true}
      />
      <PlainContentContainer
        imgSrc={HRGenie}
        buttonDisplay={true}
        iconDisplay={true}
        svg={hrSvg}
        heading="HR Genie"
        description="Summon the best talent and manage workforce charms with HR Genie, your go-to for all things HR."
        handleButtonClick={() => navigate("/platform/hr-genie")}
      />
      <BgContentContainer
        imgSrc=""
        svg={hrSvg}
        buttonDisplay={true}
        iconDisplay={true}
        heading="Payroll Genie"
        description="Let payroll complexities vanish with Payroll Genie, automating your financial enchantments with ease."
        handleButtonClick={() => navigate("/platform/payroll-genie")}
      />

      <PlainContentContainer
        imgSrc=""
        svg={hrSvg}
        buttonDisplay={true}
        iconDisplay={true}
        heading="Recruiter Genie"
        description="Conjure the perfect candidates for your team. Recruiter streamlines your hiring with a touch of magic."
        handleButtonClick={() => navigate("/platform/recruiter-genie")}
      />

      <BgContentContainer
        imgSrc=""
        svg={hrSvg}
        buttonDisplay={true}
        iconDisplay={true}
        heading="Client Manager Genie"
        description="Keep client details under your spell. Client Manager ensures every interaction is magical, building lasting bonds."
        handleButtonClick={() => navigate("/platform/client-manager-genie")}
      />
      <PlainContentContainer
        imgSrc=""
        svg={hrSvg}
        buttonDisplay={true}
        iconDisplay={true}
        heading="KPIs Generator Genie"
        description="Craft and track KPIs with wizardry precision. KPIs Generator Genie helps align your goals with magical accuracy."
        handleButtonClick={() => navigate("/platform/performance-genie")}
      />
      <PlatformCards />
      <DemoCard />
      <Footer />
    </>
  );
};

export default Platform;
