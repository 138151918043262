import Footer from "../../common/Footer";
import DemoCard from "./DemoCard";
import Fetures from "./Features";
import HeroSection from "./HeroSection";
import IntroSection from "./IntroSection";
import ModulesView from "./ModulesCarousel";
import ReviewsSection from "./ReviewsSection";
import Testimonial from "./Testimonial";
import VideoSection from "./VideoSection";

const Home = () => {
  return (
    <>
        <HeroSection />
        <VideoSection />
        <Fetures />
        <IntroSection />
        <ModulesView />
        <Testimonial />
        <ReviewsSection />
        <DemoCard />
        <Footer />
    </>
  )
}

export default Home;
