import "./index.css";

const Testimonial: React.FC = () => {
  return (
    <div className="testimonial-container">
      <div className="card-container">
        <div className="testimonial-card">
          <div className="testimonial-content">
            <p className="testimonial-logo storefront-hero-heading">
              Enabling your <br />
              <span className="shadow-text">Organization’s</span> Success
            </p>
            <div className="testimonial-h6">
              <li>Easy Navigation</li>
              <li>Notification Alerts</li>
              <li>AI Writer Co-Pilot</li>
              <li>24/7 AI Chatbot Assistance</li>
            </div>
            <div className="testimonial-hover_content">
              <p>
                Experience unparalleled efficiency in your day-to-day operations
                and unlock the true potential of your organization with our
                holistic HR solutions. Our comprehensive suite in HrGenie lets
                you monitor, analyze and track your company goals and empowers
                you to focus on strategic growth and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="image-container">
        <div className="colored-container">
        </div>
          <span className="colored-rotated-text">MISGENIE</span>
      </div>
    </div>
  );
};

export default Testimonial;
