import "./index.css";
import React from "react";

interface MarqueeCardProps {
  icon: string;
  heading: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
  className?: string;
}

const MarqueeCard: React.FC<MarqueeCardProps> = ({
  icon,
  heading,
  description,
  buttonText,
  onButtonClick,
  className,
}) => {
  return (
    <div style={{ height: "23rem" }} className={`.marquee-card ${className}`}>
      <div className="img-container">
        <div className="img">
          <img src={icon} alt="Icons" width="auto" height="110" />
        </div>
      </div>
      <div className="content-container">
        <span className="heading">{heading}</span>
        <p className="info">{description}</p>
        <button type="button" onClick={onButtonClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default MarqueeCard;
