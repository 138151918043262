import { Suspense } from "react";
import { TriangleLoader } from "../common/ReactLoader";
import LandingPagesLayout from "../layout";
import Home from "../pages/Home";
import Platform from "../pages/Platform";
import AboutUs from "../pages/AboutUs";
import Services from "../pages/Services";
import Pricing from "../pages/Pricing";
import ContactUs from "../pages/ContactUs";
import Demo from "../pages/Demo";
import AiGenie from "../pages/PlatformFeatures/AiGenie";
import HrGenie from "../pages/PlatformFeatures/HrGenie";
import ClientManagerGenie from "../pages/PlatformFeatures/ClientManagerGenie";
import RecruiterGenie from "../pages/PlatformFeatures/RecruiterGenie";
import PayrollGenie from "../pages/PlatformFeatures/PayrollGenie";
import PerformanceGenie from "../pages/PlatformFeatures/PerformanceGenie";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import CookiePolicyPage from "../pages/CookiePolicy";
import TermsandConditionsPage from "../pages/TermsAndConditions";
import PageNotFound from "../pages/404/PageNotFound";
import { Routes, Route } from "react-router-dom";


interface Route {
  pageUrl: string;
  title: string;
}

const RouterIndex: React.FC = () => {

  return (
    <div>
      <Suspense
        fallback={
          <div className="suspense-blur-screen">
            <TriangleLoader />
          </div>
        }
      >
        <Routes>
          <Route element={<LandingPagesLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/platform/ai-genie" element={<AiGenie />} />
            <Route path="/platform/hr-genie" element={<HrGenie />} />
            <Route
              path="/platform/client-manager-genie"
              element={<ClientManagerGenie />}
            />
            <Route
              path="/platform/recruiter-genie"
              element={<RecruiterGenie />}
            />
            <Route path="/platform/payroll-genie" element={<PayrollGenie />} />
            <Route
              path="/platform/performance-genie"
              element={<PerformanceGenie />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsandConditionsPage />}
            />
          </Route>
          {/* Not Found */}
          <Route path="*" element={<PageNotFound />} />          
        </Routes>
      </Suspense>
    </div>
  );
};

export default RouterIndex;
