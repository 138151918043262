import React from "react";
import BgContentContainer from "../../../../Platform/BgContentContainer";
import {
  OrgManagementConstants
} from "../../../../../constants/PlatformAttributeConstants";
import PlainContentContainer from "../../../../Platform/PlainContantContainer";
import { storeFrontQuestion } from "../../../../../common/Genie/questions";


const PlatformOrganization = () => {
  return (
    <>
      <div>
        <div>
          
          <BgContentContainer
            imgSrc={OrgManagementConstants.Org_Management_Departments.img}
            heading={OrgManagementConstants.Org_Management_Departments.heading}
            description={
              OrgManagementConstants.Org_Management_Departments.description
            }
            arrayName={storeFrontQuestion.organization}
            questionId={3}
          />
          <PlainContentContainer
            imgSrc={OrgManagementConstants.Org_Management_Designations.img}
            heading={OrgManagementConstants.Org_Management_Designations.heading}
            description={
              OrgManagementConstants.Org_Management_Designations.description
            }
            arrayName={storeFrontQuestion.organization}
            questionId={4}
          />
          <BgContentContainer
            imgSrc={OrgManagementConstants.Org_Management_JobTitles.img}
            heading={OrgManagementConstants.Org_Management_JobTitles.heading}
            description={
              OrgManagementConstants.Org_Management_JobTitles.description
            }
            arrayName={storeFrontQuestion.organization}
            questionId={5}
          />
          <PlainContentContainer
            imgSrc={OrgManagementConstants.Org_Management_Teams.img}
            heading={OrgManagementConstants.Org_Management_Teams.heading}
            description={
              OrgManagementConstants.Org_Management_Teams.description
            }
            questionId={6}
            arrayName={storeFrontQuestion.organization}
          />
          <BgContentContainer
            imgSrc={OrgManagementConstants.Org_Management_AssetsCreation.img}
            heading={
              OrgManagementConstants.Org_Management_AssetsCreation.heading
            }
            description={
              OrgManagementConstants.Org_Management_AssetsCreation.description
            }
            questionId={7}
            arrayName={storeFrontQuestion.organization}
          />
          <PlainContentContainer
            imgSrc={OrgManagementConstants.Org_Management_AssetsAssigning.img}
            heading={
              OrgManagementConstants.Org_Management_AssetsAssigning.heading
            }
            description={
              OrgManagementConstants.Org_Management_AssetsAssigning.description
            }
            questionId={8}
            arrayName={storeFrontQuestion.organization}
          />
        </div>
      </div>
    </>
  );
};
export default PlatformOrganization;
