import "./index.css";
import { useNavigate } from "react-router-dom";

const HeroSection: React.FC = () => {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact-us");
  }

  return (
    <div className="services-hero-container">
      <div className="hero-content-container">
        <span className="service-hero-heading storefront-hero-heading">
          Unite your teams and accomplish more together.
        </span>
        <span className="service-description storefront-description">
          Simplify collaboration, align your teams, and increase productivity
          with MisGenie versatile all-in-one solution designed for enterprises.
        </span>
        <button className="storefront-green-button" onClick={handleButtonClick}>
          Try For Free
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
