import React from "react";
import PrivacyPolicy from "./Components";
import Footer from "../../common/Footer";
import DemoCard from "../Home/DemoCard";
import HeroSection from "./HeroSection";

const PrivacyPolicyPage = () => {
  return (
    <>
      <HeroSection />
      <PrivacyPolicy />
      <DemoCard />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
