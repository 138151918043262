import Footer from "../../../common/Footer";
import HeroSection from "../../Platform/HeroSection";
import PlatformRecruitment from "../components/RecruitmentFeatures";

const RecruiterGenie = () => {
  return (
    <>
      <HeroSection />
      <PlatformRecruitment />
      <Footer />
    </>
  );
};

export default RecruiterGenie;
