import React from "react";
import "./index.css";
interface DemoVideoProps {
  videoId: string;
}

const MisgenieDemoVideo: React.FC<DemoVideoProps> = ({ videoId }) => {
  return (
      <iframe
        width="80%"
        height="90%"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="Demo video player"
        style={{ border: "0", borderRadius: "10px" }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
  );
};

export default MisgenieDemoVideo;
