import React from "react";

const VideoSection: React.FC = () => {
  return (
    <div className="home-video-section">
      <video
        preload="metadata"
        src={
          "https://api-sub.misgenie.app/storage/v1/object/public/MisgenieProduction/Misgenie_Trailer.mp4?t=2024-07-26T13%3A39%3A02.823Z"
        }
        controls={false}
        autoPlay
        loop
        muted
        width={"100%"}
        height={"auto"}
      />
    </div>
  );
};

export default VideoSection;
