import "./index.css";
import genieLamp from "../../../assets/svgs/platformSvgs/genie-lamp.svg";


const HeroSection: React.FC = () => {
  return (
    <div className="hero-container">
      <img src={genieLamp} alt="Genie Icon" className="hero-icon" />
      <span className="hero-heading">Discover the Future of Information Management</span>
    </div>
  );
};

export default HeroSection;
