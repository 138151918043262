import React from "react";
import BgContentContainer from "../../../Platform/BgContentContainer";
import PlainContentContainer from "../../../Platform/PlainContantContainer";
import { PerformanceConstants } from "../../../../constants/PlatformAttributeConstants";
import { storeFrontQuestion } from "../../../../common/Genie/questions";

const PlatformPerformance = () => {
  return (
    <>
      <div>
        <div>
        <BgContentContainer
            imgSrc={
                PerformanceConstants.Performance_Management_Kpi_Attribute.img
              }
              heading={
                PerformanceConstants.Performance_Management_Kpi_Attribute.heading
              }
              description={
                PerformanceConstants.Performance_Management_Kpi_Attribute
                  .description
              }
              questionId={1}
              arrayName={storeFrontQuestion.performance}
          />
          <PlainContentContainer
            imgSrc={
                PerformanceConstants.Performance_Management_Performance_Metrices
                  .img
              }
              heading={
                PerformanceConstants.Performance_Management_Performance_Metrices
                  .heading
              }
              description={
                PerformanceConstants.Performance_Management_Performance_Metrices
                  .description
              }
              questionId={2}
              arrayName={storeFrontQuestion.performance}
          />
          <BgContentContainer
            imgSrc={PerformanceConstants.Performance_Management_Kpi_Scale.img}
            heading={
              PerformanceConstants.Performance_Management_Kpi_Scale.heading
            }
            description={
              PerformanceConstants.Performance_Management_Kpi_Scale.description
            }
            questionId={3}
            arrayName={storeFrontQuestion.performance}
          />
        </div>
      </div>
    </>
  );
};
export default PlatformPerformance;
