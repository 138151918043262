import React, { useState } from "react";
import {
  Steps,
  message,
  Button,
  Radio,
  Space,
  Tooltip,
  Modal,
  Upload,
} from "antd";
import { Checkbox, Form, Input } from "antd";
import { Row, Col } from "antd";
import {
  insertSubscribeAvatarApi,
  insertSubscriberOrgLogoApi,
  storeCustomPricingFormData,
} from "../services";
import CheckboxValueType from "antd/es/checkbox/Group";
import d_dp from "../../../assets/images/blankProfile.png";

import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
  domaincheck,
  restrictToNumbers,
  validateEmail,
} from "../../../common/Validation";

const PricingForm: React.FC<any> = () => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [form] = Form.useForm();
  const [modalStep, setModalStep] = useState(0);
  const [selectedEmployeeRange, setSelectedEmployeeRange] = useState<any>("");
  const [selectedModules, setSelectedModules] = useState<typeof CheckboxValueType[]>(
    []
  );
  const [logoUrl, setLogoUrl] = useState<File | null>(null);
  const [previewLogoOpen, setPreviewLogoOpen] = useState(false);
  const [previewLogoImage, setPreviewLogoImage] = useState<
    string | null | undefined
  >(null);
  const [previewProfileImage, setPreviewProfileImage] = useState<
    string | null | undefined
  >(null);
  const [userProfileUrl, setUserProfileUrl] = useState<File | null>(null);
  const [userProfilepreviewOpen, setuserProfilePreviewOpen] = useState(false);
  const [orgName, setOrgName] = useState<string>();
  const [orgPhoneNo, setOrgPhoneNo] = useState<string>();
  const [orgEmail, setEmail] = useState<string>();
  const [headquarter, setHeadQuater] = useState<string>();
  const [submitButtonLoader, setSubmitButtonLoader] = useState<boolean>(false);

  const checkboxDescriptions1 = [
    "Organization Management",
    "Employee Management",
    "Core HR",
    "Attendance & Leave",
    "Payroll",
    "Performance",
    "Recruitment",
    "Integerations",
    "Policy Document Management",
  ];
  const checkboxDescriptions2 = [
    "1-49",
    "50-199",
    "200-499",
    "500-1000",
    "1000+",
  ];
  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      setSubmitButtonLoader(true);
      let logPath: any = "";
      let profilePath: any = "";
      if (logoUrl) {
        const fileExt = logoUrl?.name.split(".").pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;
        logPath = await insertSubscriberOrgLogoApi(filePath, logoUrl);
      }
      if (userProfileUrl) {
        const fileExt = userProfileUrl?.name.split(".").pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;
        profilePath = await insertSubscribeAvatarApi(filePath, userProfileUrl);
      }
      const body = {
        feature_list: selectedModules,
        employee_bracket: selectedEmployeeRange,
        organization_phone_no: orgPhoneNo,
        organization_email: orgEmail,
        organization_name: orgName,
        headquarter: headquarter,
        admin_first_name: values.firstName,
        admin_last_name: values.lastName,
        admin_email: values.adminEmail,
        cnic: values.cnic,
        organization_avatar: logPath ? logPath?.data?.publicUrl : "",
        admin_avatar: profilePath ? profilePath?.data?.publicUrl : "",
        demo_delivered_status: false,
      };
      const data = await storeCustomPricingFormData(body);

      setSubmitButtonLoader(false);
      if (data.status == 201) {
        message.success("Form submitted successfully!");
        form.resetFields();
        setModalStep(0);
      } else {
        message.error("Sorry! There is some external error.");
      }
    } catch {
      message.error("Sorry! There is some external error.");
    }
  };
  const handleRadioChange = (e: any) => {
    setSelectedEmployeeRange(e.target.value);
  };

  const handleNextModal = async () => {
    try {
      await form.validateFields();
      setModalStep((prevStep) => prevStep + 1);
    } catch (error) {
      message.error("Please fill out all required(*) fields.");
    }
  };
  const handlePreviousModal = () => {
    setModalStep((prevStep) => prevStep - 1);
  };
  const handleCheckboxChange = (checkedValues: typeof CheckboxValueType[]) => {
    setSelectedModules(checkedValues);
  };

  const handleLogoFileChange = (file: File) => {
    setLogoUrl(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewLogoImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleCancel = () => setPreviewLogoOpen(false);
  const handleProfileFileChange = (file: File) => {
    setUserProfileUrl(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewProfileImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleuserProfileCancel = () => setuserProfilePreviewOpen(false);
  const uploadLogoButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8, color: "var(--green-color-primary-light)" }}>
        upload Logo
      </div>
    </div>
  );
  const uploadProfileButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8, color: "var(--green-color-primary-light)" }}>
        Upload Profile
      </div>
    </div>
  );

  const steps = [
    {
      title: "Features",
      content: (
        <Form layout="vertical" form={form}>
          <Row>
            <span className="pricing-card-modal-title2">
              Let us know what you are interested in
            </span>
          </Row>
          <Row>
            <Col span={24}>
              <span className="modal-content-heading1">
                First, which features are you interested in?
              </span>
              <hr className="pricing-modal-horizontal-line" />
              <Form.Item
                name="modules"
                label="Modules"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one module!",
                  },
                ]}
              >
                <Checkbox.Group onChange={handleCheckboxChange}>
                  <Space direction="vertical">
                    {checkboxDescriptions1.map((description) => (
                      <Checkbox value={description}>{description}</Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="global-row-content-right">
              <Button
                className="storefront-green-button pricing-form-button"
                key="next"
                onClick={handleNextModal}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      title: "Employee's Range",
      content: (
        <Form layout="vertical" form={form}>
          <Row>
            <span className="pricing-card-modal-title2">
              Great, What is your growth state?
            </span>
          </Row>
          <Row>
            <Col span={24}>
              <span className="modal-content-heading1">
                How many employees do your company have?
              </span>
              <hr className="pricing-modal-horizontal-line" />
              <Form.Item
                name="totalEmployees"
                label="Total Employees"
                rules={[
                  {
                    required: true,
                    message: "Please select the total number of employees!",
                  },
                ]}
              >
                <Radio.Group onChange={handleRadioChange}>
                  <Space direction="vertical">
                    {checkboxDescriptions2.map((description) => (
                      <Radio value={description}>{description}</Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="global-row-content-right">
              <Space>
                <Button
                  key="previous"
                  className="storefront-default-btn pricing-form-button"
                  onClick={handlePreviousModal}
                >
                  Previous
                </Button>
                <Button
                  className="storefront-green-button pricing-form-button"
                  key="next"
                  onClick={handleNextModal}
                >
                  Continue
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      title: "Organization Details",
      content: (
        <Form layout="vertical" form={form}>
          <Row>
            <span className="pricing-card-modal-title2">
              Great, Could you please share your organization's details?
            </span>
          </Row>
          <hr className="pricing-modal-horizontal-line" />
          <Row gutter={[16, 16]}>
            <Col span={24} className="sf-logo-container">
              <Form.Item name="orglogo">
                <Upload
                  name="orglogo"
                  accept=".png,.jpg,.jpeg"
                  listType="picture-card"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={(file) => {
                    handleLogoFileChange(file);
                    return false;
                  }}
                  onRemove={() => {
                    setLogoUrl(null);
                  }}
                  onPreview={() => setPreviewLogoOpen(true)}
                  defaultFileList={[
                    {
                      uid: "-1",
                      name: "test.png",
                      status: "done",
                      url: previewLogoImage || "",
                    },
                  ]}
                  style={{ borderColor: "var(--green-color-primary-light)" }}
                  className="globel-upload-button"
                >
                  {!logoUrl && uploadLogoButton}
                </Upload>
                <Modal
                  open={previewLogoOpen}
                  title={"Logo Preview"}
                  footer={null}
                  onCancel={handleCancel}
                  wrapClassName="set-logo-preview-position"
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewLogoImage || ""}
                  />
                </Modal>
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label="Organization Name"
                name="organizationName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Organization Name!",
                  },
                ]}
              >
                <Input
                  placeholder="Ex: Misgenie"
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label="Organization Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Organization Phone Number!",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        value.length > 3 &&
                        !phoneUtil.isValidNumber(
                          phoneUtil.parseAndKeepRawInput(value)
                        )
                      ) {
                        return Promise.reject("Invalid phone number");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  onChange={setOrgPhoneNo}
                  className="pricing-form-phone-input"
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label="Organization Official Email"
                name="officalOaganzationEmail"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email!",
                  },
                  {
                    validator: (_, value) => {
                      if (value && !validateEmail(value)) {
                        return Promise.reject("Invalid Email Address!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter organization official email..."
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Headquarter" name="headquarter">
                <Input
                  placeholder="Ex: Lahore, Pakistan"
                  onChange={(e) => setHeadQuater(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="global-row-content-right">
              <Space>
                <Button
                  key="previous"
                  className="storefront-default-btn pricing-form-button"
                  onClick={handlePreviousModal}
                >
                  Previous
                </Button>
                <Button
                  className="storefront-green-button pricing-form-button"
                  key="next"
                  onClick={handleNextModal}
                >
                  Continue
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      title: "Admin Details",
      content: (
        <Form layout="vertical" form={form}>
          <Row>
            <span className="pricing-card-modal-title2">
              Great, What is your growth state?
            </span>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <span className="modal-content-heading1">
                Where can we reach you? This ensures you get the best experience
                possible.
              </span>
              <hr className="pricing-modal-horizontal-line" />
            </Col>
            <Col span={24} className="sf-logo-container">
              <Form.Item name="userprofile">
                <Upload
                  name="orglogo"
                  accept=".png,.jpg,.jpeg"
                  listType="picture-card"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={(file) => {
                    handleProfileFileChange(file);
                    return false;
                  }}
                  onRemove={() => {
                    setPreviewProfileImage(null);
                  }}
                  onPreview={() => setuserProfilePreviewOpen(true)}
                  defaultFileList={[
                    {
                      uid: "-1",
                      name: "test.png",
                      status: "done",
                      url: previewProfileImage || "",
                    },
                  ]}
                >
                  {!previewProfileImage && uploadProfileButton}
                </Upload>
                <Modal
                  open={userProfilepreviewOpen}
                  title={"Logo Preview"}
                  footer={null}
                  onCancel={handleuserProfileCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewProfileImage || d_dp}
                  />
                </Modal>
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                className="request-demo-create-organization-text"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Admin First Name!",
                  },
                ]}
              >
                <Input placeholder="Enter first name..." />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                className="request-demo-create-organization-text"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Admin Last Name!",
                  },
                ]}
              >
                <Input placeholder="Enter surname..." />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                className="request-demo-create-organization-text"
                label="CNIC"
                name="cnic"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Admin CNIC!",
                  },
                  {
                    validator: (_, value) => {
                      if (value && !restrictToNumbers(value)) {
                        return Promise.reject("Invalid CNIC!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Enter CNIC..." />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label="Official Email"
                name="adminEmail"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Admin Email!",
                  },
                  {
                    validator: (_, value) => {
                      if (value && !validateEmail(value)) {
                        return Promise.reject("Invalid Email Address!");
                      }
                      return Promise.resolve();
                    },
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        !domaincheck(
                          form?.getFieldValue("officalOaganzationEmail"),
                          value
                        )
                      ) {
                        return Promise.reject(
                          "Email domain not Match with comapany Email"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Enter email..." />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="global-row-content-right">
              <Space>
                <Button
                  key="previous"
                  className="storefront-default-btn pricing-form-button"
                  onClick={handlePreviousModal}
                >
                  Previous
                </Button>
                <Button
                  className="storefront-green-button pricing-form-button"
                  key="finish"
                  htmlType="submit"
                  onClick={onFinish}
                  loading={submitButtonLoader}
                >
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  return (
    <div className="pricing-form-container">
      <div className="pricing-form-sub-container">
        <Row>
          <Col span={24} className="pricing-form-heading-container">
            <span className="pricing-form-heading storefront-hero-heading">
              Explore Our Subscription!
            </span>
          </Col>
        </Row>
        <Steps current={modalStep} className="local-create-steps-modal">
          {steps.map((step, index) => (
            <Steps.Step
              key={index}
              title={step.title}
              description={`Step ${index + 1}`}
              className="local-create-steps-inner-container"
            />
          ))}
        </Steps>
        <div className="steps-content create-organization-text">
          {steps[modalStep].content}
        </div>
      </div>
    </div>
  );
};

export default PricingForm;
