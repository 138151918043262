import { Link } from "react-router-dom";
import { Button } from "../common/Elements/Composition";
import {
  AwardIcon,
  CheckIcon,
  CheckSingleTickIcon,
  ControlIcon,
  RobotIcon,
  SecurityIcon,
  SparkleIcon,
} from "../common/Elements/Icons/Icons";
import Arshad from "../assets/images/aboutpage/Team/Arshad.webp";
import Noman from "../assets/images/aboutpage/Team/Noman-Ahmad.webp";
import Salman from "../assets/images/aboutpage/Team/M-Salman.webp";
import Khansa from "../assets/images/aboutpage/Team/khansa.webp";
import Zaman from "../assets/images/aboutpage/Developers/zaman.jpeg";
import Hamza from "../assets/images/aboutpage/Developers/hamza.jpg";
import Hafeez from "../assets/images/aboutpage/Developers/hafeezullah.jpg";


export const HomePageConstants = {
  //  Home Constant Data
  Hero_Section: {
    title_02: "The Ultimate One-Stop Management Solution",
    subtitle:
      "Meet MisGenie, your organization's game-changer. MisGenie is your passport to productivity, revolutionizing the way you manage your organization. ",
  },
  About_Section: {
    About_title_01: "The ",
    About_title_02: "Genie ",
    About_title_03: " that grants all your HR wishes!",
    subtitle:
      "MisGenie revolutionizes the way you handle HR tasks, delivering a seamless and integrated HRIS portal that simplifies your workflows, enhances employee engagement, and improves your bottom line.",
  },
};
// Footer Constant Data
export const FooterData = {
  FooterSection: [
    {
      title: "About Us",
      tab01: "Our Story",
      tab02: "Our Team",
      tab03: "Careers",
    },
    {
      title: "Policies",
      tab01: "Privacy Policy",
      tab02: "Terms and Conditions",
      tab03: "Cookie Policy",
    },
    {
      title: "Contact Us",
      tab01: "support@misgenie.com",
      tab02: "(951) 916-0439",
      tab03: "Johar Town, Lahore, Pakistan",
    },
  ],
  bottomfooter: "MisGenie © 2023 All Rights Reserved.",
};
// Book free Demo Page
export const ContactPage = {
  Contact: {
    Title01: "Streamline your  ",
    Title02: "HR operations ",
    Title03: "with MisGenie ",
    subtitle: "We just need a few details from you below!",
    tagLine: "Don't hesitate! Our team is waiting to hear from you 😉",
  },
};
//Platform Page

export const PlatformPage = {
  header: {
    title: "Collaborate & Connect with Your Team",
    subtitle: "Real-Time Insights and Analytics for Better Decision-Making",
    intro_para:
      "Communication is key to success, and with MisGenie's platform, you can collaborate and connect with your team like never before. Our platform includes features like group chat, project management, and task tracking, so you can easily stay connected with your team and keep everyone on the same page. With MisGenie, you'll foster a collaborative work environment that drives results.",
  },
};

// Enterprise Page
export const EnterprisePage = {
  Header: {
    title01: "Unite your teams and accomplish",
    styledtitle: " more together.",
    subtitle:
      "Simplify collaboration, align your teams, and increase productivity with MisGenie's versatile all-in-one solution designed for enterprises.",
    Joinus: "Join 800,000+ highly productive teams",
    BenefitsList: [
      {
        benefit: "Increased efficiency",
        Icon: <CheckIcon />,
      },
      {
        benefit: "Better communication",
        Icon: <CheckIcon />,
      },
      {
        benefit: "Improved employee experience",
        Icon: <CheckIcon />,
      },
    ],
  },
  WhyEnterprise: {
    minititle: "solutions",
    title01: "What makes MisGenie the ",
    title_02: "top choice",
    title_03: " for Enterprise productivity?",
    info: [
      {
        title: "World-class Security",
        Icon: <SecurityIcon />,
      },
      {
        title: "Robust automation",
        Icon: <RobotIcon />,
      },
      {
        title: "Advanced Controls & Permissions",
        Icon: <ControlIcon />,
      },
      {
        title: "All-in-one solution",
        Icon: <AwardIcon />,
      },
      {
        title: "Enhanced productivity",
        Icon: <SparkleIcon />,
      },
    ],
  },
};

//Pricing Page
export const PricingPage = {
  cardsData: [
    {
      title: "Unlimited",
      subtitle: "Best for small teams",
      headClass: "card-header card1-header-color",
      priceClass: "card-price card1-price-color",
      cardClass: "price-card card1-border",
      monthlyPrice: 15,
      yearlyPrice: 10,
      button: (
        <Link to={"/contactus"}>
          <Button className="Try-it-button price-Try-it-btn card1-btn-color">
            Get Started
          </Button>
        </Link>
      ),
      features: [
        {
          title: "People record management",
          Icon: <CheckSingleTickIcon />,
        },
        {
          title: "Leave management",
          Icon: <CheckSingleTickIcon />,
        },
        {
          title: "Scheduling of Training Events",
          Icon: <CheckSingleTickIcon />,
        },
      ],
    },
    {
      title: "Business",
      subtitle: "Best for medium teams",
      headClass: "card-header card2-header-color",
      priceClass: "card-price card2-price-color",
      cardClass: "price-card card2-border",
      monthlyPrice: 25,
      yearlyPrice: 20,
      button: (
        <Link to={"/contactus"}>
          <Button className="Try-it-button price-Try-it-btn card2-btn-color">
            Get Started
          </Button>
        </Link>
      ),
      features: [
        {
          title: "All in Unlimited",
          Icon: <CheckSingleTickIcon />,
        },
        {
          title: "Timesheets",
          Icon: <CheckSingleTickIcon />,
        },
        {
          title: "360 Feedback",
          Icon: <CheckSingleTickIcon />,
        },
      ],
    },
    {
      title: "Enterprise",
      subtitle: "Best for large teams",
      headClass: "card-header card3-header-color",
      priceClass: "card-price card3-price-color",
      cardClass: "price-card card3-border",
      monthlyPrice: 50,
      yearlyPrice: 40,
      button: (
        <Link to={"/contactus"}>
          <Button className="Try-it-button price-Try-it-btn card3-btn-color">
            Contact Sales
          </Button>
        </Link>
      ),
      features: [
        {
          title: "All in Business",
          Icon: <CheckSingleTickIcon />,
        },
        {
          title: "Internal/External Training Parties",
          Icon: <CheckSingleTickIcon />,
        },
        {
          title: "OKR",
          Icon: <CheckSingleTickIcon />,
        },
      ],
    },
    {
      title: "Custom",
      subtitle: "Contact us for details",
      headClass: "card-header card4-header-color",
      priceClass: "card-price card4-price-color",
      cardClass: "price-card card4-border",
      button: (
        <Link to={"/contactus"}>
          <Button className="Try-it-button price-Try-it-btn card4-btn-color">
            Contact Sales
          </Button>
        </Link>
      ),
      monthlyPrice: 75,
      yearlyPrice: 100,
      features: [
        {
          title: "Contact Sales for details",
          Icon: <CheckSingleTickIcon />,
        },
      ],
    },
  ],
  Faqs: [
    {
      question: "What kind of payment options do you accept?",
      answer:
        "We accept credit cards and bank transfers. You can choose your preferred payment method during the checkout process.",
    },
    {
      question: "Do you offer a free trial of your platform?",
      answer:
        "Yes, we offer a 14-day free trial of our platform so that you can try it out before committing to a paid subscription.",
    },
    {
      question:
        "Is there a limit to the number of users I can have on the platform?",
      answer:
        "No, there is no limit to the number of users you can have on the platform. You can add as many users as you need to.",
    },
    {
      question: "What level of support is included with my subscription?",
      answer:
        "We offer a range of support options depending on your subscription level. All plans include email support, while our higher-level plans offer phone and chat support as well.",
    },
    {
      question: "Can I upgrade or downgrade my subscription at any time?",
      answer:
        "Yes, you can upgrade or downgrade your subscription at any time. Simply log in to your account and make the necessary changes.",
    },
    {
      question: "What happens if I cancel my subscription?",
      answer:
        "If you cancel your subscription, your account will remain active until the end of your billing cycle. After that, you will no longer have access to the platform or any of your data.",
    },
  ],
};

export const ServicesPageData = {
  Services_Hr_Strategy: {
    heading: "Strategy",
    description:
      "Our HR consultancy team at MisGenie specializes in recognizing the distinctive challenges and possibilities that exist within your company. We work collaboratively with you to overcome any obstacles that are causing any hindrance in the way of achieving your objectives. We provide customized solutions geared towards attaining success.",
  },
  Services_Hr_Recruitment: {
    heading: "Recruitment",
    description:
      "At MisGenie, we understand that selecting the ideal candidate for your team entails more than simply matching their abilities. It involves identifying individuals with a positive attitude and approach to work. Our consultants can assist you in choosing candidates who not only possess requisite skills but also have a positive and optimistic mindset so they can readily integrate into your organization and drive its success right from the start.",
  },
  Services_Hr_Training: {
    heading: "Training",
    description:
      "Employee training is not only advantageous but also crucial in order to advance your business's financial objectives. Our consultancy team offers innovative concepts and tactics that will motivate your staff members to continuously enhance their skills, resulting in elevated performance levels beyond what they have achieved before.",
  },
  Services_Hr_Employee_Engagement: {
    heading: "Employee Engagement",
    description:
      "Maintaining workforce enthusiasm and involvement is crucial for a successful working environment. MisGenie's advisors provide individualized assistance and strategies to adapt to your specific requirements and ethics, whether you encounter obstacles or aspire to enrich your organizational ethos.",
  },
  Services_Hr_Organizational_Development: {
    heading: "Organizational Development",
    description:
      "With MisGenie's expertise in organizational development, you can unleash your organization's maximum potential. Our consultants will closely examine your current situation and collaborate with you to improve your objectives and strategies, which will lead to productive improvement and increased value throughout the entire workplace. Together with MisGenie, success becomes not just a possibility - it is destined. Join us on this transformative venture and witness your organization soar to unparalleled heights!",
  },
};

// About us Page
export const AboutUsPageData = {
  heading: "Transforming Businesses with Innovative Solutions",
  description:
    "Your partner in revolutionizing organizational management. Discover how our innovative software solutions empower businesses to streamline operations, enhance productivity, and achieve sustainable growth.",
  Infosection: {
    challange:
      "In the dynamic realm of business, challenges often arise as formidable adversaries. However, at MisGenie, we view these challenges not merely as obstacles but as opportunities for innovation and advancement. With unwavering determination and creative problem-solving, we confront each challenge head-on, striving to enhance organizational efficiency and foster growth. Our journey is guided by a fearless spirit, fueled by the desire to surmount any obstacle hindering our clients' path to success.",
    solution:
      "At MisGenie, innovation is at the core of everything we do. Armed with creativity, expertise, and cutting-edge technology, we concoct solutions that defy convention and exceed expectations. From revolutionizing HR management to reimagining organizational operations, we specialize in crafting bespoke solutions tailored to the unique needs of each client. Our secret sauce lies in our ability to blend ingenuity with practicality, delivering transformative results that propel businesses forward.",
    OurPath:
      "As we embark on our journey, we reflect on how far we've come and the milestones we've achieved. From humble beginnings to a trailblazing force in the industry, MisGenie's odyssey is marked by resilience, determination, and unwavering dedication to our mission. With each step forward, we navigate new territories, forge strategic alliances, and pioneer innovative solutions that shape the future of organizational management. Our path is illuminated by the successes of our clients, fueling our passion to continue pushing the boundaries of what's possible.",
  },
  Developers: [
    {
      image: Zaman,
      name: "Muhammad Zaman Qadir",
      role: "Leads Software Engineer",
      linkedin: "https://www.linkedin.com/in/zaman068/",
    },

    {
      image: Hafeez,
      name: "Hafeez Ullah",
      role: "Associate Software Engineer",
      linkedin: "https://www.linkedin.com/in/hafeez-ullah-a37a8b166/",
    },
    {
      image: Hamza,
      name: "Hamza Adnan",
      role: "Associate Software Engineer",
      linkedin: "https://www.linkedin.com/in/hamzaadnan/",
    },
  ],

  Team: [
    {
      image: Arshad,
      name: "Muhammad Arshad",
      role: "Founder, CEO",
      linkedin: "https://www.linkedin.com/in/marshadofficial/",
    },
    {
      image: Noman,
      name: "Noman Ahmad",
      role: "Co-Founder, CIO",
      linkedin: "https://www.linkedin.com/in/noman-pro/",
    },
    {
      image: Salman,
      name: "Muhammad Salman Ahmad",
      role: "Co-Founder, COO",
      linkedin: "https://www.linkedin.com/in/salman-26/",
    },
    {
      image: Khansa,
      name: "Khansa Mueen",
      role: "Co-Founder, Staff Software Engineer",
      linkedin: "https://www.linkedin.com/in/khansa-mueen/",
    },
  ]
};

// privacy policy page
export const privacyPolicyPage = {};
