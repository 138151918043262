import supabase, { supabase_client_for_operation_portal_schema } from "../../services/supabase";

const customCatchError = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const storeCustomPricingFormData = async (body: any): Promise<any> => {
  try {
   
    const res = await supabase_client_for_operation_portal_schema
      .from("subscriber")
      .insert(body);

    return res;
  } catch (error) {
    return error;
  }
};
export const insertSubscriberOrgLogoApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("organizationLogos")
      .upload(filePath, file);

    if (res.error) {
      throw res.error;
    }
    const publicURL = supabase.storage
      .from("organizationLogos")
      .getPublicUrl(filePath);
    return publicURL;
  } catch (error) {
    return error;
  }
};
export const insertSubscribeAvatarApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("employeeProfile")
      .upload(filePath, file);

    if (res.error) {
      throw res.error;
    }
    const publicURL = supabase.storage
      .from("employeeProfile")
      .getPublicUrl(filePath);
    return publicURL;
  } catch (error) {
    return error;
  }
};
 