// org imports
import comapnyImage from "../assets/images/landingPage-imges/organization/company-table.webp";
import branchesImage from "../assets/images/landingPage-imges/organization/branches-table.webp";
import departmentImage from "../assets/images/landingPage-imges/organization/department-table.webp";
import designationImage from "../assets/images/landingPage-imges/organization/designation-table.webp";
import jobTitleImage from "../assets/images/landingPage-imges/organization/jobtitle-table.webp";
import teamsTitleImage from "../assets/images/landingPage-imges/organization/image.webp";
import assetsCreationImage from "../assets/images/landingPage-imges/organization//asset-creation-table.webp";
import assetsAssigningImage from "../assets/images/landingPage-imges/organization/asset-assigning-table.webp";

// employee imports
import employeeProfileManagementImage from "../assets/images/landingPage-imges/employeeManagement/company-employee-table.webp";
import employeeContactImage from "../assets/images/landingPage-imges/employeeManagement/contacts-table.webp";
import employeeExperienceImage from "../assets/images/landingPage-imges/employeeManagement/experience-table.webp";
import employeeSkillsImage from "../assets/images/landingPage-imges/employeeManagement/skills-table.webp";
import employeeCertificationsImage from "../assets/images/landingPage-imges/employeeManagement/certification-table.webp";
import employeeQualificaionsImage from "../assets/images/landingPage-imges/employeeManagement/qualification-table.webp";
import employeeRelativesImage from "../assets/images/landingPage-imges/employeeManagement/relative-table.webp";
import employeeBankImage from "../assets/images/landingPage-imges/employeeManagement/banks-table.webp";
import employeeSalaryImage from "../assets/images/landingPage-imges/employeeManagement/salaries-table.webp";

// payroll imports
import PayrollBenefitsImage from "../assets/images/landingPage-imges/payroll/benefits.webp";
import PayrollSalariesImage from "../assets/images/landingPage-imges/payroll/salaries.webp";
import PayrollPaySlipGenerationImage from "../assets/images/landingPage-imges/payroll/pay-slip-generation.webp";
import PayrollOvertimeClaimImage from "../assets/images/landingPage-imges/payroll/overtime-claim.webp";
import PayrollClaimRequestImage from "../assets/images/landingPage-imges/payroll/claim-request.webp";
import PayrollConfigImage from "../assets/images/landingPage-imges/payroll/payroll-config.webp";
import PayrollTaxationImage from "../assets/images/landingPage-imges/payroll/taxation-table.webp";

// performance imports
import KPIAttributePerformanceImage from "../assets/images/landingPage-imges/performance/kpi-attribute.webp";
import PerformanceMatricesImage from "../assets/images/landingPage-imges/performance/performance-metrices.webp";
import KPIScalePerformanceImage from "../assets/images/landingPage-imges/performance/kpi-scale.webp";

// time imports
import myTimeSheetImage from "../assets/images/landingPage-imges/attendance/time-sheet.webp";
import attendanceLogsImage from "../assets/images/landingPage-imges/attendance/attandence-logs.webp";
import leaveApprovalImage from "../assets/images/landingPage-imges/attendance/leave-approval.webp";

// recruitment imports
import dashboardImage from "../assets/images/landingPage-imges/recruitment/dashboard.webp";
import jobPostsRecruitmentImage from "../assets/images/landingPage-imges/recruitment/job-post.webp";
import cvBankRecruitmentImage from "../assets/images/landingPage-imges/recruitment/cv-bank.webp";

// policy document import
import deductionPolicyImage from "../assets/images/landingPage-imges/policyManagement/deduction-table.webp";
import timingPolicyImage from "../assets/images/landingPage-imges/policyManagement/timing-table.webp";
import overtimePolicyImage from "../assets/images/landingPage-imges/policyManagement/overtime-table.webp";
import absentPolicyImage from "../assets/images/landingPage-imges/policyManagement/absent-table.webp";
import leavesPolicyImage from "../assets/images/landingPage-imges/policyManagement/leaves-table.webp";

export const PlatformDropDownConstants = {
  Org_Data: {
    title: "Organization Management",
    description:
      "Empower administrators to create and oversee the structure of their organization, including companies, branches, departments, and teams, for effective collaboration and management.",
    link: "/Platform/organization",
    Company: {
      companyTitle: "Companies",
      companyText:
        "Admin can create and manage different companies within the system, each with its unique attributes and settings.",
    },
    branches: {
      Title: "Branches",
      Text: "Easily establish and oversee multiple branches or locations, providing a clear hierarchy for your organization.",
    },
    departments: {
      Title: "Departments",
      Text: "Efficiently organize various departments or units, streamlining workflow and responsibilities.",
    },
    teams: {
      Title: "Teams",
      Text: "Foster teamwork and collaboration by creating and managing teams of employees, enhancing productivity and communication.",
    },
  },

  Emp_Data: {
    title: "Employee Management",
    description:
      "The employee directory database includes employee profiles, contact info, work details, and family info for efficient communication, collaboration, and HR tasks like recruitment and performance evaluation.",
    link: "/Platform/employee",
    EmployeeProfile: {
      employeeTitle: "Employee Profiles",
      employeeText:
        "The employee directory provides detailed professional profiles.",
    },
    SalaryManagement: {
      employeeTitle: "Salary Management",
      employeeText:
        "Designed to optimize salary management for improved organizational efficiency.",
    },
  },

  Attendace_Data: {
    title: "Attendance",
    description:
      "The attendance module offers HR personnel a detailed monthly overview of employee attendance, including check-in/out times, late arrivals, missing punches, workable hours, and total work hours.",
    link: "/Platform/attendance",
    time_sheet: {
      Title: "My Time Sheet",
      Text: "The attendance module provides HR with a detailed monthly overview for effective attendance management.",
    },
    attendance_logs: {
      Title: "Attendance Logs",
      Text: "The module records precise check-in/out times for HR to track employee punctuality and adherence to work hours.",
    },
  },

  Recruitment_Data: {
    title: "Recruitment",
    description:
      "Enables HR to assess recruitment aspects, including candidate qualifications, staffing needs, and job postings.",
    link: "/Platform/recruitment",
    dashboard_overview: {
      Title: " Dashboard Overview",
      Text: "Streamline job posting management and analysis for HR to attract the right talent effectively.",
    },
    cv_bank: {
      Title: "CV Bank",
      Text: "Conveniently access and review candidate resumes and qualifications in the centralized CV Bank sub-feature for HRs.",
    },
  },

  Payroll: {
    title: "Payroll",
    description:
      "Payroll system handles salary, deductions, perks, bonuses, taxes, and provident funds, as well as generates and manages employee salary slips.",
    link: "/Platform/payroll",
    benefits: {
      Title: "Benefits",
      Text: "Easily manage employee benefits, including healthcare, insurance, and retirement plans.",
    },
    salaries: {
      Title: "Salaries",
      Text: "Efficiently handle salary calculations, deductions, and payment processing.",
    },
    pay_slip_generation: {
      Title: "Pay Slip Generation",
      Text: "Generate detailed pay slips for employees, providing transparency in earnings and deductions.",
    },
    overtime_claim: {
      Title: "Overtime Claim",
      Text: "Simplify the process of recording and compensating employees for overtime hours worked.",
    },
  },

  policyDocumentData: {
    title: "Policy Documents Management",
    description:
      "Enable admin and HR to create and manage policies for employees, covering deductions, timing, overtime, and absenteeism/leave, ensuring clear guidelines and compliance.",
    link: "/Platform/policy",
    deduction: {
      Title: "Deduction Policies",
      Text: "Easily configure and enforce deduction policies to manage various payroll deductions accurately.",
    },
    timing: {
      Title: "Timing Policies",
      Text: "Define and implement timing policies to regulate employee work schedules and attendance.",
    },
    overtime: {
      Title: "Overtime Policies",
      Text: "Create policies to manage and compensate for overtime hours worked by employees.",
    },
    absent: {
      Title: "Absent/Leave Policies",
      Text: "Establish clear guidelines and rules for employee absenteeism and leave requests, promoting transparency and consistency.",
    },
  },

  PerformanceData: {
    title: "Performance Management",
    description:
      "Empower admin and HR to create and assess employee performance by defining KPI attributes, scales, and matrices, fostering continuous improvement and goal alignment.",
    link: "/Platform/performance",
    KPI_att: {
      Title: " KPI Attribute",
      Text: "Define key performance indicators (KPIs) with specific attributes and metrics to evaluate employee performance effectively.",
    },
    KPI_scale: {
      Title: "KPI Scale",
      Text: "Create customized scales for KPI assessment, allowing for tailored measurement of employee achievements.",
    },
    performance: {
      Title: "Performance Matrices",
      Text: "Establish performance matrices to evaluate and track employee progress and contributions against organizational goals.",
    },
  },

  mobileViewData: {
    title: "Mobile App",
    description:
      "Connectivity is the key – Put the entire HRMS experience in the hands of your workforce.",
    link: "/Platform/mobileapp",
    Dashboard: {
      Title: "Dashboard",
      Text: "Our user-friendly dashboard lets HRs effortlessly access insights on employee data such as check-in and check-out times, attendance, onboarding, work anniversaries, and more.",
    },
    Attendance: {
      Title: "Attendance",
      Text: "The attendance module offers HR staff a detailed monthly overview of employee attendance, including check-in/out times, late arrivals, missed punches, workable and total work hours.",
    },
    Leave: {
      Title: "Leave Request",
      Text: "Team members can use the Requests module to lodge leave requests for time off, vacations, or temporary absences from work.",
    },
  },

  integerationViewData: {
    title: "Integerations",
    description:
      "We have integerated Slack, Jira, Microsoft Teams, Quick Book and some other softwares and provided the users a vast experience of these apps.",
    link: "/Platform/integerations",
    Slack: {
      Title: "Slack",
      Text: "Messaging app for teams, facilitating communication and collaboration in real-time.",
    },
    Jira: {
      Title: "Jira",
      Text: "Project management tool for tracking tasks, issues, and software development processes.",
    },
    Microsoft: {
      Title: "Microsoft Teams",
      Text: "Unified communication platform for chat, meetings, file sharing, and collaboration within organizations.",
    },
    Quick: {
      Title: "Quick Book",
      Text: " Accounting software designed for small businesses, offering invoicing, expense tracking, and financial reporting.",
    },
  },
};

export const OrgManagementConstants = {
  Org_Management_Hero_Section: {
    title: "Organization Management",
    sub_heading:
      "Streamlined HR, Admin, Employee management system for organizations.",
  },
  Org_Management_text_Section:
    "The Organization Management module simplifies HR, Administration,and Employee management with comprehensive tools. It includes detailed departmental structures, employee designations, job titles, and team hierarchies, streamlining communication and resource allocation. This fosters a collaborative work environment, driving organizational success through improved productivity and employee satisfaction.",
  Org_Management_Companies: {
    heading: "Companies",
    description:
      "The company section within organization management acts as a central hub for key business information, offering insights into company names, specialties, and contact details. This repository enhances communication and collaboration across the organization, supporting strategic planning and resource allocation. It streamlines operations and fosters synergy among business units.",
    img: comapnyImage,
  },
  Org_Management_Branches: {
    heading: "Branches",
    description:
      " The Branch feature within the organization component centralizes detailed information on each branch, facilitating informed decisions and efficient operations management. It offers easy access to locations, contact details, and operational specifics, enabling strategic planning and streamlined daily activities. ",
    img: branchesImage,
  },
  Org_Management_Departments: {
    heading: "Departments",
    description:
      "This subsection offers insights into departmental goals, structures, and functions, serving as a key information repository. It enhances understanding of the organization's inner workings, supports strategic decision-making, and promotes effective inter-departmental communication and collaboration, thereby boosting operational efficiency and synergy.",
    img: departmentImage,
  },
  Org_Management_Designations: {
    heading: "Employee Designations",
    description:
      "This module clarifies employee responsibilities and designations, enhancing contribution to organizational goals. It categorizes employees by roles, improving efficiency and workflow, and facilitates clear communication by defining each individual's contribution to the company's objectives.",
    img: designationImage,
  },
  Org_Management_JobTitles: {
    heading: "Job Titles",
    description:
      "The job titles section ensures uniform understanding of roles across the organization, clarifying responsibilities and streamlining communication. This standardization reduces ambiguity, fosters a cohesive culture, and supports talent management and recruitment by clearly defining position requirements and expectations.",

    img: jobTitleImage,
  },
  Org_Management_Teams: {
    heading: "Teams Management",
    description:
      " Efficient collaboration is fostered through the inclusion of the feature of team organization within the management module. This outlines how teams are structured. By promoting a cohesive work environment and encouraging teamwork, it enhances productivity and accelerates project delivery. This functionality facilitates seamless communication and coordination among team members, enabling them to collaborate more effectively toward shared goals. ",

    img: teamsTitleImage,
  },
  Org_Management_AssetsCreation: {
    heading: "Assets Creation",
    description:
      "This asset management module streamlines the creation and tracking of company assets, detailing model, condition, and specifications. It centralizes information to promote transparency and informed decision-making for procurement, maintenance, and disposal, thus enhancing efficiency, reducing costs, and ensuring regulatory compliance.",

    img: assetsCreationImage,
  },
  Org_Management_AssetsAssigning: {
    heading: "Assets Assigning",
    description:
      "This module streamlines asset allocation to employees and tracks usage, allowing admin and HR to assign assets efficiently by recording employee details and asset status. It improves transparency and accountability with tracking and reporting features, enhancing communication and ensuring assets support organizational goals effectively. ",

    img: assetsAssigningImage,
  },
};

export const EmployeeManagementConstants = {
  Empl_Management_Hero_Section: {
    title: "Employee Management",
    sub_heading:
      "The employee management section streamlines HR tasks and enhances team communication.",
  },
  Empl_Management_text_Section:
    "The employee management section centralizes detailed profiles, enhancing organizational efficiency by streamlining communication and supporting HR tasks. It aids in quick decision-making for project assignments, promotions, and managing company benefits.",

  Empl_Management_Profile: {
    heading: "Employee Profiles Management",
    description:
      "The Employee Profiles Management module consolidates personnel data in the HR system, providing insights into employees' backgrounds, qualifications, and skills. It boosts HR efficiency by enabling quick access for decision-making and team composition, offering advanced search features to find ideal candidates. Integrated with key HR functions and equipped with strict privacy controls, it fosters a collaborative and secure workplace.",
    img: employeeProfileManagementImage,
  },
  Empl_Management_Contact_Information: {
    heading: "Contact Information Management",
    description:
      "The Contact Information Management module enhances internal communication by centralizing employee contact details, facilitating quick and easy collaboration. It simplifies reaching out to colleagues and supports efficient teamwork by ensuring all necessary contact information is readily available for immediate use. ",
    img: employeeContactImage,
  },
  Empl_Management_Work_Experience: {
    heading: "Work Experience Management",
    description:
      "The Work Experience Management module offers a detailed view of each employee's work history, including roles, responsibilities, and achievements, to better understand their professional growth and capabilities. It supports strategic HR decisions for promotions, project staffing, and team building by providing insights into experience and skill development.",
    img: employeeExperienceImage,
  },
  Empl_Management_Skills: {
    heading: "Skills Management",
    description:
      "The Skills Management module catalogs employees' skills and expertise, providing a clear overview of the organization's talent pool. It helps managers and HR identify strengths for projects, team building, and development plans. This module enhances personnel utilization, supports training programs, and aids in succession planning, boosting the company's agility and competitiveness.",
    img: employeeSkillsImage,
  },
  Empl_Management_Certificaions: {
    heading: "Certificaions Management",
    description:
      "The Certifications Management module streamlines the tracking of employee certifications, detailing the institute, date, and title of each credential. It facilitates the identification of qualified personnel for specific tasks, compliance, and training opportunities, enhancing HR's ability to manage professional development and maintain industry standards efficiently. This module ensures a well-organized and accessible certification database, supporting strategic planning and operational excellence.",

    img: employeeCertificationsImage,
  },
  Empl_Management_Qualifications: {
    heading: "Qualifications Management",
    description:
      "The Qualifications Management module streamlines the assessment of employees' educational backgrounds, facilitating role suitability evaluations and workforce planning. It records academic achievements, supporting strategic training, and development initiatives. This efficient overview of qualifications aids in aligning personnel with organizational goals, enhancing decision-making and promoting a skilled workforce.",

    img: employeeQualificaionsImage,
  },
  Empl_Management_Relative: {
    heading: "Relative Details Management",
    description:
      "The Relative Details Management module enriches employee profiles with relatives' information, aiding in emergency contact management, benefits administration, and fostering work-life balance. It supports a more inclusive work environment by recognizing family contexts, enhancing policy development, and ensuring compliance. This streamlined approach to HR management promotes a supportive and informed organizational culture.",

    img: employeeRelativesImage,
  },
  Empl_Management_Banks: {
    heading: "Banks Management",
    description:
      "The Banks Management section centralizes team members' banking information, enhancing payroll and financial transactions. It improves the efficiency and accuracy of salary disbursements and expense reimbursements, ensuring secure and timely financial operations while upholding confidentiality and compliance standards. ",

    img: employeeBankImage,
  },
  Empl_Management_Salaries: {
    heading: "Salaries Management",
    description:
      "The Salaries Management aspect focuses on streamlining salary administration, directly contributing to enhanced organizational efficiency. It simplifies the process of calculating, distributing, and managing wages, ensuring accuracy and timely payments. This system supports financial planning and analysis, allowing for a more strategic allocation of resources and improving employee satisfaction through reliable compensation practices.",

    img: employeeSalaryImage,
  },
};

export const PayrollConstants = {
  Payroll_Management_Hero_Section: {
    title: "Payroll Management",
    sub_heading: "Payroll system generates and manages employee salary slips.",
  },
  Payroll_Management_text_Section:
    "The payroll system efficiently manages various aspects of employee compensation, including salaries, deductions, perks, bonuses, taxes, and provident funds. It generates and maintains detailed employee salary slips, ensuring accurate record-keeping and transparent financial transactions. With its comprehensive functionality, the system simplifies payroll processing and enhances administrative efficiency within the organization.",

  Payroll_Management_Empl_Benefits: {
    heading: "Employeement Benefits",
    description:
      "This feature simplifies the management of employee benefits such as healthcare, insurance, and retirement plans. It offers a user-friendly platform for HR to efficiently administer and track benefits, ensuring employee satisfaction and compliance with company policies.",
    img: PayrollBenefitsImage,
  },
  Payroll_Management_Salaries: {
    heading: "Salaries",
    description:
      "This feature streamlines salary calculations, deductions, and payment processing tasks. It provides an efficient platform for HR to manage payroll, ensuring accurate and timely salary disbursements while maintaining compliance with regulations.",
    img: PayrollSalariesImage,
  },
  Payroll_Management_PaySlip_Generation: {
    heading: "Pay Slip Generation",
    description:
      "This feature automates the creation of detailed pay slips, offering transparency in employees' earnings and deductions. It ensures accurate documentation of payroll information, enhancing communication and trust between employees and the organization.",
    img: PayrollPaySlipGenerationImage,
  },
  Payroll_Management_Overtime_Claim: {
    heading: "Overtime Claim",
    description:
      "This feature streamlines the recording and compensation of overtime hours worked by employees. It simplifies the process, ensuring accurate tracking and timely payment for additional hours put in by staff members.",
    img: PayrollOvertimeClaimImage,
  },
  Payroll_Management_Claim_Request: {
    heading: "Claim Requests",
    description:
      "This functionality facilitates the seamless submission and processing of various employee claims. It streamlines the request process, ensuring timely approval and efficient management of reimbursements, expenses, and other claims. With automated workflows and easy tracking, it enhances transparency and reduces administrative burden for HR teams.",

    img: PayrollClaimRequestImage,
  },
  Payroll_Management_Payroll_Config: {
    heading: "Payroll Configuration",
    description:
      "This tool offers customizable settings to tailor the payroll system to specific organizational needs. It enables administrators to configure salary structures, deductions, tax rules, and payment schedules with ease. With flexible options and comprehensive controls, it ensures accurate and compliant payroll processing while accommodating diverse employee requirements.",

    img: PayrollConfigImage,
  },
  Payroll_Management_Tax_Management: {
    heading: "Taxation Management",
    description:
      "This feature simplifies the handling of tax-related tasks within the organization. It provides tools for calculating, withholding, and reporting taxes accurately, ensuring compliance with tax laws and regulations. With streamlined processes and automated functionalities, it helps optimize tax management while reducing administrative burdens for HR and finance teams.",

    img: PayrollTaxationImage,
  },
};

export const PerformanceConstants = {
  Performance_Management_Hero_Section: {
    title: "Performance Management",
    sub_heading:
      "Empower admin and HR to create and assess employee performance by defining KPI attributes",
  },
  Performance_Management_text_Section:
    " Empower admin and HR to create and assess employee performance by defining KPI attributes, scales, and matrices, fostering continuous improvement and goal alignment.",

  Performance_Management_Kpi_Attribute: {
    heading: "KPI Attribute",
    description:
      "This feature allows for the definition of key performance indicators (KPIs) with specific attributes and metrics. It enables effective evaluation of employee performance by providing detailed criteria for assessment. With customizable settings, organizations can align KPIs with their strategic goals and objectives seamlessly.",
    img: KPIAttributePerformanceImage,
  },
  Performance_Management_Performance_Metrices: {
    heading: "Performance Matrices",
    description:
      "This tool helps establish performance matrices for evaluating and tracking employee progress. It enables organizations to align employee contributions with overarching organizational goals, fostering clarity and accountability. With detailed metrics and tracking capabilities, it facilitates continuous improvement and strategic alignment within the workforce.",
    img: PerformanceMatricesImage,
  },
  Performance_Management_Kpi_Scale: {
    heading: "KPI Scale",
    description:
      "This feature enables the creation of customized scales for assessing KPIs, providing tailored measurement of employee achievements. It offers flexibility in defining assessment criteria, allowing organizations to align performance evaluations with specific goals and objectives effectively. With customizable scales, organizations can ensure accurate and comprehensive assessment of employee performance across various metrics.",
    img: KPIScalePerformanceImage,
  },
};

export const AttendanceConstants = {
  Attendance_Management_Hero_Section: {
    title: "Attendance Management",
    sub_heading:
      "To streamlines HR's review of employee attendance, salary calculations and compensation oversight.",
  },
  Attendance_Management_text_Section:
    "The attendance module provides HR with a detailed monthly report, encompassing employee attendance data like check-in/out times and work hours. This information aids in precise salary calculations and enables informed decisions for fair compensation practices.",

  Attendance_Management_Time_Sheet: {
    heading: "My Time Sheet",
    description:
      "The attendance module streamlines HR's tracking of employee attendance with a detailed monthly sheet, providing a comprehensive overview that aids in effective analysis and management.",
    img: myTimeSheetImage,
  },
  Attendance_Management_Attendance_Logs: {
    heading: "Attendance Logs Management",
    description:
      "This module records precise check-in and check-out times, offering HR insights into employee punctuality and adherence to work hours. It aids in assessing attendance patterns, identifying trends, and fostering accountability.",
    img: attendanceLogsImage,
  },
  Attendance_Management_Leave_Approval: {
    heading: "Leave Approval Management",
    description:
      "Streamline the process of requesting and approving leave with this efficient module. From submitting requests to tracking approvals, it offers a centralized platform for managing employee leave effectively. Simplify workflow, enhance transparency, and ensure timely responses with Leave Approval Management.",
    img: leaveApprovalImage,
  },
};

export const RecruitmentConstants = {
  Recruitment_Management_Hero_Section: {
    title: "Recruitment Management",
    sub_heading: "Enables HR to assess recruitment aspects.",
  },
  Recruitment_Management_text_Section:
    "This recruitment tool empowers HR professionals with a comprehensive platform for talent acquisition. It enables meticulous assessment of candidate qualifications, strategic analysis of staffing needs, and optimization of job postings. By centralizing recruitment tasks, it facilitates efficient management from job requisition to candidate selection, enhancing collaboration and communication while ensuring informed, timely decisions aligned with organizational goals.",

  Recruitment_Management_Dashboard_Overview: {
    heading: "Dashboard Overview",
    description:
      "This module provides HR with a detailed overview of recruitment activities, enabling thorough assessment of candidate qualifications, staffing requirements, and the effectiveness of job postings, streamlining decision-making processes.",
    img: dashboardImage,
  },
  Recruitment_Management_Job_Post: {
    heading: "Job Posts Management",
    description:
      "Providing a centralized platform for recruitment oversight, this feature allows HR to gain a comprehensive understanding of candidate profiles, staffing needs, and the performance of job postings. It streamlines decision-making processes by offering a holistic view of recruitment activities, empowering HR to optimize their strategies effectively.",
    img: jobPostsRecruitmentImage,
  },
  Recruitment_Management_CV_Bank: {
    heading: "CV Bank",
    description:
      "Simplifying the hiring process, this sub-feature offers HRs convenient access to candidate resumes and qualifications stored in a centralized database. By providing easy retrieval and review of CVs, it streamlines the candidate selection process, enabling HRs to make informed decisions efficiently.",
    img: cvBankRecruitmentImage,
  },
};

export const PolicyDocumentConstants = {
  PolicyDocument_Management_Hero_Section: {
    title: "Policy Documents Management",
    sub_heading:
      "Enable admin and HR to create and manage policies for employees",
  },
  PolicyDocument_Management_text_Section:
    "Empower admin and HR to efficiently craft and oversee comprehensive policies for employees, encompassing deductions, timing, overtime, and absenteeism/leave. Ensure adherence to regulations and clarity in guidelines, fostering a conducive work environment. This enables effective management of personnel affairs, promoting transparency and compliance across the organization.",

  PolicyDocument_Management_Deductoin_Policy: {
    heading: "Deduction Policies",
    description:
      "This feature allows for the definition of key performance indicators (KPIs) with specific attributes and metrics. It enables effective evaluation of employee performance by providing detailed criteria for assessment. With customizable settings, organizations can align KPIs with their strategic goals and objectives seamlessly.",
    img: deductionPolicyImage,
  },
  PolicyDocument_Management_Timing_Policy: {
    heading: "Timing Policies",
    description:
      "Establish clear timing policies to regulate employee work schedules and attendance, ensuring consistency and productivity. Define parameters for punctuality and attendance expectations, promoting a reliable workforce. Implement systems to monitor adherence to these policies, fostering accountability and efficiency. By enforcing these guidelines, optimize operational effectiveness and mitigate disruptions. This structured approach cultivates a culture of accountability and punctuality within the organization.",
    img: timingPolicyImage,
  },
  PolicyDocument_Management_Overtime: {
    heading: "Overtime Policies",
    description:
      "Develop comprehensive overtime policies to effectively manage and compensate for additional hours worked by employees. Establish clear guidelines outlining eligibility criteria, compensation rates, and approval procedures. Implement systems to track and monitor overtime hours, ensuring accuracy and fairness in compensation. By adhering to these policies, promote employee well-being and fair labor practices while optimizing resource utilization. This structured approach fosters transparency and compliance, enhancing organizational efficiency and employee satisfaction.",
    img: overtimePolicyImage,
  },

  PolicyDocument_Management_Absent_Policy: {
    heading: "Absent Policies",
    description:
      "Establishing transparent and consistent guidelines for employee absent is paramount in fostering a cohesive and productive workplace environment. By clearly defining expectations and procedures, employees gain clarity on reporting processes and understand the importance of accountability. These guidelines promote transparency by ensuring all team members are aware of the standards and consequences associated with absenteeism, thereby fostering a culture of reliability and responsibility within the organization.",
    img: absentPolicyImage,
  },
  PolicyDocument_Management_Leave_Policy: {
    heading: "Leave Policies",
    description:
      "Crafting comprehensive leave policies is essential to effectively manage employee time off, defining procedures for requesting, approving, and tracking leave. These policies ensure fairness and consistency while balancing operational needs and employee well-being. By providing clear guidelines and expectations, leave policies promote transparency and accountability within the organization. This structured approach facilitates efficient management of workforce resources and fosters a supportive and productive work environment.",
    img: leavesPolicyImage,
  },
};
