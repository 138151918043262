import Footer from "../../../common/Footer";
import HeroSection from "../../Platform/HeroSection";
import PlatformPayroll from "../components/PayrollFeatures";

const PayrollGenie = () => {
  return (
    <>
       <HeroSection />
       <PlatformPayroll />
       <Footer />
    </>
  )
}

export default PayrollGenie;
