import Footer from "../../common/Footer";
import DemoCard from "../Home/DemoCard";
import HeroSection from "./HeroSection";

const ContactUs = () => {
  return (
    <>
      <HeroSection />
      <DemoCard />
      <Footer />
    </>
  );
};

export default ContactUs;
