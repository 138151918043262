import { createClient } from "@supabase/supabase-js";
const supabaseUrlCopy = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonCopy = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabaseservicerolekey = process.env.REACT_APP_SUPABASE_SERVICE_ROLE;
const supabaseUrl = supabaseUrlCopy;
const supabaseKey = supabaseAnonCopy;
const supabase = createClient(supabaseUrl, supabaseKey);
export const supabase_admin =  createClient(supabaseUrl, supabaseservicerolekey);
export const supabase_client_for_operation_portal_schema = createClient(
  supabaseUrl,
  supabaseKey,
  {
    db: { schema: "operation_portal" },
  }
);
export const supabase_client_for_private_schema = createClient(
  supabaseUrl,
  supabaseservicerolekey,
  {
    db: {
      schema: "private",
    },
  }
);
export const supabase_client_for_Org_schema = (org_id) =>
  createClient(supabaseUrl, supabaseservicerolekey, {
    db: {
      schema: `organization_${org_id}`,
    },
  });

export default supabase;
